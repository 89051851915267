import React from 'react';

import StatsCard from 'components/StatsCard';
import {
  StatsContainer,
  InfoIcon,
  EmailsSentText,
} from 'components/StatsCard/styles';
import { useMediaQuery, from } from 'styles/media';
import { Switch, Route, Redirect } from 'react-router-dom';
import testingVar from 'utils/testingVar';
import { MainContainer, MainContainerTitle } from 'components/MainContainer';
import { Tooltip } from '@scriptaddicts/yamm-ui-components';
import { RouterTab } from 'components/Link';
import { useConnect } from './connect';
import { Tabs, NewButton, MainContainerHeader } from './styles';
import SentMailMerges from './Sent';
import ScheduledMailMerges from './Scheduled';
import { plurify } from '../../../utils/plurify';

const MailMerges = () => {
  const { url, path, counters, scheduledCampaignCount, plan } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <MainContainer title="Mail merges">
      <MainContainerHeader>
        <MainContainerTitle>Mail Merges</MainContainerTitle>
        {!isMobile && (
          <NewButton
            variant={plan === 'FREE' ? 'secondary' : 'primary'}
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/360020742280-Overview-of-sending-your-first-mail-merge"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Quick tutorial"
          >
            Quick tutorial
          </NewButton>
        )}
      </MainContainerHeader>
      <StatsContainer>
        <StatsCard
          variant="merges"
          number={scheduledCampaignCount?.campaignCount}
          text={`Mail ${plurify(
            scheduledCampaignCount?.campaignCount,
            'merge',
          )} scheduled`}
        />
        <StatsCard
          variant="sent-this-month"
          number={counters.thisMonth}
          content={
            <EmailsSentText type="span" size="extraSmall" width="regular">
              {plurify(counters.thisMonth, 'Email')} sent this month
              <Tooltip
                tip="Excluding form submission notifications."
                placement="bottom"
                key="tooltip"
              >
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
        />
        <StatsCard
          variant="emails"
          number={counters.allTime}
          content={
            <EmailsSentText type="span" size="extraSmall" width="regular">
              {plurify(counters.allTime, 'Email')} sent all time
              <Tooltip
                tip="Excluding form submission notifications."
                placement="bottom"
              >
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
        />
      </StatsContainer>
      <Tabs>
        <RouterTab
          data-test-id={testingVar('view-scheduled')}
          to={`${url}/scheduled`}
        >
          Scheduled
        </RouterTab>
        <RouterTab to={`${url}/sent`}>Sent</RouterTab>
      </Tabs>
      <Switch>
        <Route path={`${path}/sent`}>
          <SentMailMerges />
        </Route>
        <Route path={`${path}/scheduled`}>
          <ScheduledMailMerges />
        </Route>
        <Route path={path}>
          <Redirect to={`${url}/sent`} />
        </Route>
      </Switch>
    </MainContainer>
  );
};

export default MailMerges;
