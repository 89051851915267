import React, { DispatchWithoutAction, FC, memo } from 'react';
import { ReactComponent as MailMerge } from 'assets/images/icons/mail-merge.svg';
import { ReactComponent as Unsubscription } from 'assets/images/icons/unsubscription.svg';
import { ReactComponent as Bounce } from 'assets/images/icons/bounce.svg';
import { ReactComponent as Team } from 'assets/images/icons/team.svg';
import { ReactComponent as Settings } from 'assets/images/icons/workspace-settings.svg';
import { ReactComponent as Tutorial } from 'assets/images/icons/mail-merge-tutorial.svg';
import { ReactComponent as Dot } from 'assets/images/icons/dot.svg';
import testingVar from 'utils/testingVar';
import { clickTutorial } from 'containers/Guest/SignUp/events';
import { Drawer, Text } from '@scriptaddicts/yamm-ui-components';
import { useMediaQuery, from } from 'styles/media';
import useUrlGroupDisclosure from 'utils/router/useUrlGroupDisclosure';
import {
  Container,
  Logo,
  Navigation,
  Item,
  ItemIcon,
  TopSection,
  BottomSection,
  Header,
  HeaderTitle,
  ItemGroupTrigger,
  ItemGroup,
  ItemGroupItem,
  CannotAccessSpacesText,
} from './styles';
import { Props, PropsWithDisclosure } from './types';
// import { ReactComponent as Template } from '../../assets/images/icons/template.svg';
import SmallCannonNavBanner from '../SmallCannonNavBanner';
import { getRoleValue, RoleValue } from '../../utils/roleValue';

const ResponsiveMenuSide: FC<Props & { onClose?: DispatchWithoutAction }> = ({
  canAccessTeamReport,
  canAccessWorkspace,
  userRole,
  cannon,
  showTutorial,
  workspace,
  disabled,
  url,
  onClose,
  canAccessBounces,
  canAccessUsers,
  ...props
}) => {
  const settings = useUrlGroupDisclosure(`${url}/space`);
  const roleValue = getRoleValue(userRole);
  const isAdmin = roleValue >= RoleValue.ADMIN;
  const isOwner = roleValue >= RoleValue.OWNER;

  return (
    <Container {...props}>
      <TopSection>
        <Header>
          <HeaderTitle>
            <Logo />
          </HeaderTitle>
        </Header>
        <Navigation data-disabled={disabled ? 'disabled' : undefined}>
          <Item to={`${url}/mail-merges`} onClick={onClose}>
            <ItemIcon as={MailMerge} />
            <Text size="extraLarge">Mail merges</Text>
          </Item>
          <Item
            data-test-id={testingVar('view-unsubscriptions')}
            to={`${url}/unsubscriptions`}
            onClick={onClose}
          >
            <ItemIcon as={Unsubscription} />
            <Text size="extraLarge">Unsubscriptions</Text>
          </Item>
          {canAccessBounces && (
            <Item
              data-test-id={testingVar('view-bounces')}
              to={`${url}/bounces`}
              onClick={onClose}
            >
              <ItemIcon as={Bounce} />
              <Text size="extraLarge">Bounces</Text>
            </Item>
          )}
          {/*
          <Item
              data-test-id={testingVar('view-templates')}
              to={`${url}/templates`}
              onClick={onClose}
          >
            <ItemIcon as={Template}/>
            <Text size="extraLarge">Templates</Text>
          </Item>
          */}
          {canAccessTeamReport && (
            <Item
              data-test-id={testingVar('view-team-report')}
              to={`${url}/team-report`}
              onClick={onClose}
            >
              <ItemIcon as={Team} />
              <Text size="extraLarge">Team report</Text>
            </Item>
          )}
          <>
            <ItemGroupTrigger {...settings}>
              <ItemIcon as={Settings} />
              <Text size="extraLarge">Space settings</Text>
            </ItemGroupTrigger>

            <ItemGroup>
              {workspace}

              {!canAccessWorkspace && (
                <CannotAccessSpacesText>
                  You cannot manage settings if you are not an owner or admin of
                  the selected space.
                </CannotAccessSpacesText>
              )}

              {canAccessWorkspace && isOwner && (
                <ItemGroupItem to={`${url}/space/general`} onClick={onClose}>
                  <ItemIcon as={Dot} />
                  <Text size="base">General</Text>
                </ItemGroupItem>
              )}

              {canAccessWorkspace && canAccessUsers && (
                <ItemGroupItem to={`${url}/space/users`} onClick={onClose}>
                  <ItemIcon as={Dot} />
                  <Text size="base">Users</Text>
                </ItemGroupItem>
              )}

              {canAccessWorkspace && isAdmin && (
                <ItemGroupItem to={`${url}/space/billing`} onClick={onClose}>
                  <ItemIcon as={Dot} />
                  <Text size="base">Billing</Text>
                </ItemGroupItem>
              )}
            </ItemGroup>
          </>
          {showTutorial && (
            <Item
              to="/get-started"
              onClick={() => {
                clickTutorial();
                if (onClose) onClose();
              }}
            >
              <ItemIcon as={Tutorial} />
              <Text size="extraLarge">Install YAMM add-on</Text>
            </Item>
          )}
        </Navigation>
      </TopSection>
      <BottomSection>
        {cannon && cannon.enabled && <SmallCannonNavBanner cannon={cannon} />}
      </BottomSection>
    </Container>
  );
};

const ResponsiveMenu: FC<PropsWithDisclosure> = ({
  children,
  show,
  onClose,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);

  if (isMobile) {
    return (
      <Drawer
        direction="left"
        responsive={false}
        close="overlay"
        width={256}
        side={<ResponsiveMenuSide {...props} onClose={onClose} />}
        show={show}
        onClose={onClose}
      >
        {children}
      </Drawer>
    );
  }

  return <ResponsiveMenuSide {...props} />;
};

export default memo(ResponsiveMenu);
